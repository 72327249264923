/* Primary site color: #5F5FFF, secondary colors: #0ADEF3, #096DDA, tertiary: gradient */
.nst-gate-page-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(95.12deg, #10D4E8 10.82%, #5F5FFF 89.38%);
    text-align: center;
}

.content-container {
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.error-code {
    font-size: 6rem;
    font-weight: bold;
    color: #5F5FFF; /* Primary color */
}

.error-message {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #096dda;
}

.buttons-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.5rem;
}

.link-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
}


.primary-btn {
    background-color: #5F5FFF; /* Primary color */
    color: white;
}


.secondary-btn {
    border-color: #0ADEF3; /* Secondary color */
    color: #096DDA; /* Darker secondary color */
}


.home-link {
    text-decoration: none;
}
