.footer-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 5rem;
}

.footer-columns {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgb(54, 56, 60);
  padding-bottom: 1rem;
}


.columns-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.column {
  flex: 1;
  margin: 0 15px;
  color: #333;
}

.column h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
}

.column ul {
  list-style-type: none;
  padding-left: 0;
}

.column ul li {
  margin-bottom: 8px;
  color: #555;
}


.column:last-child {
  margin-right: 0;
}

.footer-column h4 {
  font-size: 16px;
  color: white;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  padding-bottom: 1rem;
  cursor: pointer;
}

.footer-column li a {
  color: rgb(177, 180, 184);
  text-decoration: none;
}

.follow-us {
  width: 15%;
  color: white;
  padding-right: 5rem;
}

.follow-us-info {
  color: rgb(177, 180, 184);
}

.follow-us-info a {
  text-decoration: underline;
  color: rgb(177, 180, 184);
}

.follow-us p {
  margin: 0px;
  line-height: 1.5;
}

.social-icons {
  display: flex;
  margin-bottom: 1rem;
}

.social-icons-container {
  width: 25px;
  /* Adjust the size of the circle */
  height: 25px;
  /* Adjust the size of the circle */
  border-radius: 50%;
  /* Make it a circle */
  background-color: transparent;
  /* Background color for the circle */
  display: flex;
  justify-content: center;
  /* Center the icon horizontally */
  align-items: center;
  /* Center the icon vertically */
  margin: 0 5px;
  border: 1px solid rgb(177, 180, 184);
}

.copyright {
  width: 100%;
  text-align: center;
}

.copyright p {
  color: rgb(177, 180, 184);
  text-align: center;
  margin-top: 20px;
}

.nst-gateway-get-in-touch-main-container {
  background: url(/src/assests/Home/footer_bg.png), linear-gradient(90deg, #096DDA 0%, #105EB3 100%);
  background-size: contain;
  background-position: right;
  margin-top: 38px;
}

.nst-gateway-footer-wrap {
  padding-top: 50px;
}

.nst-footer-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #FFFFFF38;
  padding-bottom: 38px;
  margin-bottom: 27px;
}

.nst-gateway-logo-main {
  background-color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  max-width: 330px;
  width: 100%;
}
.nst-gateway-logo-main a{
  display: flex;
}
.nst-gateway-logo-main svg {
  width: 288px;
  height: auto;
}

.nst-gateway-on-social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.nst-gateway-on-social-media>a {
  /* background-color: #FFFFFF3D;
  border-radius: 8px;
  width: 47px;
  height: 47px;*/
  display: flex; 
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.nst-gateway-contact-us-add-call-sec {
	display: flex;
  gap: 10px;
	/* justify-content: space-between; */
}
.nst-gateway-contact-us-address {
	max-width: 66%;
	width: 100%;
}
.nst-gateway-contact-us-add-call-sec>div {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 15px;
  cursor: pointer;
}
.nst-gateway-contact-us-add-call-sec > div > div {
	display: flex;
}
.nst-gateway-contact-us-details-content-section-left {
	max-width: 42.2%;
	width: 100%;
}

.nst-gateway-contact-us-details-content-section-right {
	max-width: 48.4%;
	width: 100%;
}

.nst-gateway-content-upper-section {
  /* display: flex;
  border-bottom: 1px solid #FFFFFF38; */
  padding-bottom: 97px;
  /* margin-bottom: 26px; */
}

.nst-gateway-content-upper-section h4 {
  color: white;
}

.nst-gateway-ready-to-accept-payment-heading-content {
	/* max-width: 65%; */
	/* width: 100%; */
	margin-bottom: 34px;
}

.nst-gateway-content-below-support-link-section .columns-container {
  padding: 0;
  margin-bottom:56px;
}
.navigate-use-btn .get-started-btn {
	background-color: transparent;
	border-radius: 30px;
	padding: 15px 51px;
	font-size: 20px;
	transition: all 0.5s ease;
	color: #fff;
	text-transform: capitalize;
	line-height: 150%;
	font-family: "Mulish", sans-serif;
	width: -moz-fit-content;
	width: fit-content;
	font-weight: 800;
	border: 1px solid #fff;
}
.navigate-use-btn .get-started-btn:hover{
  background-color: #fff;
  color: #096DDA;

}
.nst-gateway-content-below-support-link-section .columns-container .column {
  margin: 0;
}

.nst-gateway-content-below-support-link-section .column h4 {
  color: white;
}

.nst-gateway-content-below-support-link-section .columns-container .column ul {
  margin-top: 9px;
}

.nst-gateway-content-below-support-link-section .columns-container .column li {
  margin-bottom: 11px;
}
.nst-gateway-content-below-support-link-section .columns-container .column li:last-child {
  margin-bottom: 0;
}

.nst-gateway-content-below-support-link-section .columns-container .column li a {
  color: var(--white);
  font-size: 18px;
  transition: all .5s ease;
  line-height: 130%;
}

.nst-gateway-content-below-support-link-section .columns-container .column li a:hover {
  text-decoration: underline;
}

.nst-gateway-content-below-support-link-section .columns-container .column li a.active {
  text-decoration: underline;
}

.nst-footer-bottom {
  text-align: center;
  color: #FFFFFF99;
  padding-bottom: 27px;
  font-size: 18px;
  line-height: 150%;
}
.nst-gateway-copy-right-notice-content {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}
@media (max-width:1200px) {
  .nst-gateway-content-below-support-link-section .columns-container {
    margin-bottom: 31px;
  }
  .nst-footer-bottom {
    font-size: 16px;
  }
  .navigate-use-btn .get-started-btn {
    font-size: 18px;
  }
}
@media (max-width:1024px) {
  .nst-footer-top {
    padding-bottom: 14px;
  }

  .nst-gateway-logo-main svg {
    width: 100%;
    height: auto;
  }

  .nst-gateway-contact-us-add-call-sec>div {
    font-size: 14px;
  }

  .nst-gateway-content-below-support-link-section .columns-container .column li a {
    font-size: 16px;
  }

  .nst-gateway-content-below-support-link-section .columns-container .column li {
    margin-bottom: 15px;
  }

  .nst-footer-bottom {
    padding-bottom: 17px;
  }
  .nst-gateway-content-upper-section {
    padding-bottom: 73px;
    margin-bottom: 0;
  }
  .navigate-use-btn .get-started-btn {
    font-size: 16px;
  }
  .nst-gateway-content-below-support-link-section .columns-container {
    margin-bottom: 10px;
  }
  .nst-gateway-contact-us-details-content-section-right {
    max-width: 53.4%;
    width: 100%;
  }
  .nst-gateway-logo-main {
    max-width: 250px;
    padding: 10px;
  }
  .nst-gateway-contact-us-address {
    max-width: 65%;
  }
  .navigate-use-btn .get-started-btn {
    font-size: 16px;
    height: 47px;
  }
}

@media screen and (max-width: 768px) {
  .footer-container {
    padding: 0 5rem;
  }

  .footer-columns {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid rgb(54, 56, 60);
    padding-bottom: 1rem;
  }

  .follow-us {
    width: 30%;
    color: white;
    padding-right: 0;
  }

  .nst-gateway-get-in-touch-main-container {
    margin-top: 18px;
  }

  .nst-gateway-footer-wrap {
    padding-top: 30px;
  }

  /* .nst-gateway-on-social-media {
    gap: 10px;
    margin-top: 27px;
    margin-bottom: 27px;
  } */

  .nst-gateway-content-below-support-link-section .columns-container .column li a {
    font-size: 14px;
  }

  .nst-gateway-content-below-support-link-section .columns-container .column li {
    margin-bottom: 10px;

  }
  .navigate-use-btn .get-started-btn {
    font-size: 14px;
  }

}
@media (max-width:767px) {
  .nst-gateway-contact-us-add-call-sec {
    gap: 7px;
  }
  .nst-gateway-contact-us-address {
    max-width: calc(60% - 10px);
  }
  .nst-gateway-content-below-support-link-section .columns-container .column li {
    margin-bottom: 5px;
  }
  .nst-gateway-contact-us-add-call-sec > div {
    gap: 9px;
  }
  .nst-gateway-content-below-support-link-section .columns-container {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 650px) {

  .nst-gateway-contact-us-details-content-section-left,
  .nst-gateway-contact-us-details-content-section-right {
    max-width: 100%;
    /* margin-bottom: 20px; */
  }

  .nst-gateway-logo-main {
    margin-bottom: 20px;
  }

  .nst-gateway-on-social-media {
    justify-content: flex-start;
    margin-top: 17px;
    margin-bottom: 17px;
  }

  .nst-gateway-contact-us-add-call-sec>div {
    font-size: 14px;
    gap: 9px;
  }

  .nst-gateway-content-upper-section {
    /* justify-content: space-between; */
    /* margin-bottom: 20px; */
    padding-bottom: 30px;
  }
  .nst-footer-bottom {
    font-size: 14px;
  }
  .nst-gateway-ready-to-accept-payment-heading-content {
  
    margin-bottom: 20px;
  }
  .nst-footer-top {
    flex-direction: column;
  margin-bottom: 0;
}
.nst-footer-bottom {
	padding-bottom: 9px;
}
.nst-gateway-contact-us-add-call-sec {
	gap: 10px;
}
.nst-gateway-contact-us-address {
	max-width: calc(65% - 10px);
}
}

@media screen and (max-width: 480px) {
  .footer-container {
    padding: 0 0;
  }

  .footer-column {
    width: 100%;
    padding: 0 .5rem;
    align-items: center;
  }

  .follow-us {
    width: 30%;
    color: white;
    padding-left: 10px;
  }

  .nst-gateway-ready-to-accept-payment-heading-content {
    max-width: 100%;
  }

  .nst-gateway-content-below-support-link-section .columns-container .column li {
    margin-bottom: 10px;
  }

  .nst-gateway-on-social-media {
    display: flex;
    justify-content: flex-start;
    margin-top: 17px;
    width: 100%;
  }

  .nst-gateway-contact-us-add-call-sec {
    flex-direction: column;
  }
  .nst-gateway-contact-us-address {
    max-width: 100%;
  }

  .nst-gateway-content-upper-section {
    flex-direction: column;
    gap: 15px;
  }

  .nst-gateway-content-below-support-link-section .columns-container .column {
    text-align: left;
    padding-bottom: 0.2rem;
  }
  .nst-gateway-content-below-support-link-section .columns-container .column ul {
    margin-top: 6px;
  }
  .nst-gateway-copy-right-notice-content {
    flex-direction: column;
  }
  .nst-footer-bottom {
    text-align: left;
  }
  .nst-footer-top {
    margin-bottom: 20px;
  }
  .nst-gateway-on-social-media > a > svg {
    width: 20px;
    height: 19px;
  }
  .nst-gateway-contact-us-add-call-sec {
    gap: 0;
  }
}