.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    margin: 0px 88px;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 2px solid rgb(242, 241, 240);
}
.disabled-link {
    pointer-events: none;
    cursor: default;
    color: inherit;
    text-decoration: none;
}

  
.logo {
    display: flex;
    align-items: flex-end;
    padding: 0px;
}
.logo img {
    width: 180px; 
}

.navigation-login {
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 0px;
}

.navigation {
    width: 25rem;
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navigation a {
    text-decoration: none;
    padding: 2rem 0rem;
    color: rgb(1,1,1);
    display: flex;
    align-items: center;
    border-bottom: 3px solid transparent;
}

.navigation a:hover {
    border-bottom: 3px solid rgb(10, 98, 207);
    color: rgb(10, 98, 207);
}

.login {
    margin: 0 2rem;
    display: flex;
    justify-content: space-between;
}
.signup-button {
    margin-right: 2rem;
}

.login-button {
    margin-right: -2rem;
}

.signup-button button {
    background-color: rgb(10, 98, 207);
    color: aliceblue;
    border: none;
    width: 8vw;
    height: 5vh;
    border-radius: 5px;
    cursor: pointer;
    vertical-align: middle;
}

.signup-button button:hover {
    background-color: rgb(0, 115, 255);
}

.login-button button {
    background-color: white;
    border-color: rgb(10, 98, 207);
    border: none;
    width: 8vw;
    height: 5vh;
    border-radius: 5px;
    cursor: pointer;
    vertical-align: middle;
}

.login-button button:hover {
    background-color: rgb(0, 115, 255);
    color: white;
}

.floating-login-form {
    /* Add styles for the floating login form */
    position: absolute; /* Position the form relative to the viewport */
    top: 10vh; /* Center the form vertically */
    right: 6vw; /* Center the form horizontally */
    background-color: transparent; /* Background color for the form */
    border: none; /* Border around the form */
    z-index: 999; /* Ensure the form is above other elements */
}

.drop-down-menu {
    display: none;
}
/* new home page css start */
header.MuiPaper-root{
    background-color: transparent;
    box-shadow: none;
}
div.MuiContainer-root {
	max-width: 1225px;
	width: 100%;
}

.scroll.MuiAppBar-root {
    background-color: var(--white);
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  }

div.MuiBox-root {
	justify-content: flex-end;
    align-items: center;
	gap: 20px;
}
.navlink-header-redirect{
    font-size: 16px;
    font-weight: 800;
    text-decoration: none;
    color: #1F2B39;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.navlink-header-redirect:hover{
    background-color: transparent;
    color: var(--purple);
}
.navlink-header-redirect.active {
    background-color: transparent;
    color: var(--purple);
}

/* .navlink-header-redirect.page-contact-us{
    border: 1px solid var(--purple);
    background-color:var(--white);
    border-radius: 30px;
    padding: 8px 33px;
    transition: all .5s ease;
    margin: 16px 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--purple);
} */
.navlink-header-redirect.page-signup {
	border: 1px solid #000;
	background-color: var(--white);
	border-radius: 30px;
	padding: 8px 20px;
	transition: all .5s ease;
	margin: 16px 0;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
    font-weight: 400;
}
.navlink-header-redirect.page-signup svg path {
	fill: #000;
    transition: all .5s ease;
}
.navlink-header-redirect.page-signup:hover{
    background-color: #000;
    color: #fff;
}
.navlink-header-redirect.page-signup:hover svg path{
    fill: #fff;
}
.navlink-header-redirect.page-login {
	background-color: #5F5FFF;
	border: 1px solid #5F5FFF;
	border-radius: 30px;
	padding: 8px 21px;
	transition: all .5s ease;
	margin: 16px 0;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
    cursor: pointer;
    font-weight: 400;
}
.navlink-header-redirect.page-login path{
    fill: #fff;
    transition: all .5s ease;
}
.navlink-header-redirect.page-login:hover{
    color: #5F5FFF;
    background-color: #fff;
}
.navlink-header-redirect.page-login:hover path{
    fill: #5F5FFF;
}
/* .navlink-header-redirect.page-contact-us:hover {
    background-color: var(--purple);
    color: var(--white);
} */

.navlink-header-redirect.page-signup a,.navlink-header-redirectpage-login a{
    display:flex;
    align-items: center;
    gap: 6px;
}
.navlink-header-redirect.page-signup a:hover svg path,.navlink-header-redirect.page-login a:hover svg path{
    fill: var(--purple);
}
.nst-header-right {
    display: none;
}
@media screen and (max-width:1200px) {
 
    div.MuiBox-root {
        gap: 25px;
    }
    .navlink-header-redirect,
    button.MuiButtonBase-root {
        font-size: 16px;
    }
    
}
@media screen and (max-width:1024px) {
    div.MuiBox-root {
        gap: 10px;
    }
    /* .navlink-header-redirect.page-contact-us {
        padding: 3px 20px;
        width: max-content;
    } */
    .navlink-header-redirect.page-signup {
        padding: 6px 14px;
    }
    .navlink-header-redirect.page-login {
        padding: 6px 14px;
    }
}
@media screen and (max-width: 900px) {
    .signup-button button {
        width: 13vw;
        height: 5vh;
    }

    .navigation {
        display: none;
    }

    .drop-down-menu {
        display: block;
    }

    .dropdown-menu a {
        text-decoration: none;
        color: rgb(1,1,1);
        align-items: center;
        border-bottom: 3px solid transparent;
    }
    .dropdown-menu a:focus,
    .dropdown-menu a:active {
        border-bottom: 3px solid rgb(10, 98, 207);
        color: rgb(10, 98, 207);
    }

    .dropdown-button {
        background-color: transparent;
        border: none;
    }

    .drop-down {
        display: block;
    }
}
@media screen and (max-width:899px) {
    .MuiPaper-root > div.MuiBox-root{
        padding: 11px;
    }
    div.MuiToolbar-root{
        justify-content: space-between;
        min-height: 68px;
    }
    .nst-gateway-hamburger-head-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 0;
    }
    .nst-gateway-header-icon-hamburger {
        display: flex;
        cursor: pointer
    }
    .nst-gateway-header-icon-hamburger svg:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 9px;
        border-radius: 5px;
       
    }
    .nst-gateway-header-icon-hamburger svg:hover rect{
        fill: var(--purple);
    }
    .navlink-header-redirect,
    button.MuiButtonBase-root {
       
        margin-top: 0px;
    }
    /* .navlink-header-redirect.page-contact-us {
        margin-bottom: 10px;
        margin-top: 10px;
        width: max-content;
        height: 36px;
    } */
    .nst-header-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .nst-gateway-on-page-scalling-auth {
        display: flex;
        justify-content: flex-end;
    }
    .nst-gateway-on-page-scalling-auth button.MuiButtonBase-root {
        min-width: unset;
        transition: all .5s ease;
    }
    .nst-gateway-on-page-scalling-auth button.MuiButtonBase-root span {
        margin-right: 0;
    }
    .nst-gateway-on-page-scalling-auth button.MuiButtonBase-root span svg {
        transition: all .5s ease;
    }
   
    .nst-gateway-on-page-scalling-auth button.MuiButtonBase-root span:hover svg path{ 
        fill: var(--purple);
    }
    .nst-gateway-hamburger-drawer-icon-close{
        cursor: pointer;
        transition: all .5s ease;
    }
    .nst-gateway-hamburger-drawer-icon-close svg{
        transition: all .5s ease;
    }
    .nst-gateway-hamburger-drawer-icon-close:hover svg {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 9px;
        border-radius: 5px;
    }
    .nst-gateway-hamburger-drawer-icon-close:hover svg rect{ 
        fill: var(--purple);
       
    }
}
@media screen and (max-width: 768px) {
    .header {
        margin: 0px 2rem;
    }
   
}

@media screen and (max-width: 480px) {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;
        margin: 0;
        padding: .5rem;
        border-bottom: 2px solid rgb(242, 241, 240);
    }
    
    .logo {
        display: flex;
        align-items: flex-end;
        padding: 0px;
    }
    .logo img {
        width: 111px; 
    }
    .drop-down-menu {
        margin-left: 2rem;
    }
    
    .navigation-login {
        display: flex;
        margin: 0;
        padding: 0;
    }
    
    .login-button {
        margin: 0;
    }

    .login-button button {
        width: 18vw;
    }
    
    .signup-button button {
        width: 25vw;
    }
}