/* Background Image Section */
.company-container {
  position: relative;
  width: 100%;
  padding: 110px 0 190px;
}

.company-container::after {
  position: absolute;
  background: url(/src/assests/Home/blue_light_bg.png);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  background-position: bottom;
  width: 100%;
  /* height: 286px; */
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  padding-top: 14.74%;
}
/* .company-values-parts-container .payment-business-card > div {
	padding-top: 80px;
} */
.company-content {
  width: 100%;
  z-index: 1;
  /* Make sure content appears above the background */
  text-align: center;
 
}

h1.company-title span {
	color: #096DDA;
}

.company-subtitle {
	/* padding: 0 10vw; */
	align-self: center;
	text-align: center;
	font-size: 16px;
	color: var(--text-color);
	line-height: 120%;
	margin: 30px auto 0;
	max-width: 767px;
	width: 100%;
}
.get-started-origin .get-started-btn {
	margin: 36px auto 0;
}
/* Values Section */
.company-values-container {
	background-color: #EAF1FC;
	padding: 100px 0 0 0;
}



.company-values-top h2 {
  text-align: center;
}

.company-values-parts-container {
	margin-bottom: 90px;
	margin-top: 130px;
  position: relative;
}
.company-values-parts-container::after {
	content: "";
	background: radial-gradient(50% 50% at 50% 50%, rgba(191, 95, 255, 0.2) 0%, rgba(191, 95, 255, 0) 100%);
	width: 814px;
	height: 814px;
	position: absolute;
	bottom: -280px;
	right: -160px;
  z-index: 0;
}
.company-values-parts {
  padding-top: 2rem;
  flex: 1;
  display: flex;
}

.company-values-parts-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  text-align: center;
}

.company-values-parts-icon {
  margin-bottom: 0.5rem;
}

.company-values-parts-content {
  text-align: left;
}

.company-values-parts-content h3 {
  margin: 0;
  font-size: xx-large;
  font-weight: 300;
  padding-bottom: 1rem;
}

.company-values-parts-content p {
  margin: 0;
  width: 60%;
  font-size: small;
  font-weight: 300;
}

/* Trusted Partner Section */

.company-trusted-partner-container {
	background-color: rgb(245, 252, 255);
	padding: 150px 0;
}
.company-trusted-partner-left-column {
	padding-right: 148px;
	max-width: calc(68.9% - 148px);
	width: 100%;
}

.company-trusted-partner-left h2 {
  font-weight: 600;
}

.company-trusted-partner-wrap > div.company-trusted-partner-right-column {
	max-width: 42.1%;
  width: 100%;
}
.company-trusted-partner-right-img-wrap {
	position: relative;
	width: 100%;
	padding-top: 69.556%;
}
.company-trusted-partner-right-img-wrap img {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	left: 0;
	object-fit: cover;
  border-radius: 30px;
  box-shadow: 0px 20px 20px 0px #1E1E6F33;

}
.company-trusted-partner-wrap {
	display: flex;
	align-items: center;
  flex-wrap: wrap;
}


.company-trusted-partner-title,
.company-trusted-partner-text {
 margin-bottom: 20px;
}

/* Company Improvements Section */



.company-improvements-container {
	padding-top: 170px;
	padding-bottom: 125px;
}
.company-improvements-title {
	text-align: center;
	max-width: 640px;
	margin: 0 auto 55px;
}

.company-improvements-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 190px;
	width: 100%;
	margin: 0 auto;
}
.company-improvements-item .company-improvements-icon{
	width: 116px;
	height: 116px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.company-improvements-item:first-child .company-improvements-icon {
 background-color: #0adef3;
}
.company-improvements-item:nth-child(2) .company-improvements-icon {
  background-color: #096DDA;
 }
 .company-improvements-item:nth-child(3) .company-improvements-icon {
  background-color: #5F5FFF;
 }
 .company-improvements-item:nth-child(4) .company-improvements-icon {
  background-color: #0adef3;
 }

 .company-improvements-item p {
	font-size: 24px;
	font-weight: 800;
	margin-top: 20px;
  text-align: center;
}

.company-improvements-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 130px;
}
.get-started-origin {
	padding: 75px 0rem;
	background-image:  url(/src/assests/Home/line-bg.png), linear-gradient(93.9deg, #10d4e8 0.61%, #096dda 63.46%);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right;
  text-align: center;
}
@media screen and (max-width:1200px) {
  .company-container {
    padding: 70px 0 110px;
  }
  .company-subtitle {
    margin: 20px auto 0;
  }
  .company-values-container {
    padding: 30px 0 0 0;
  }
  .company-values-parts-container {
    margin-bottom: 50px;
    margin-top: 90px;
  }
  .company-trusted-partner-container {
    padding: 60px 0;
  }
  .company-trusted-partner-left-column {
    padding-right: 38px;
  }
  .get-started-origin {
    padding: 55px 0rem;
  
  }
  .get-started-origin .get-started-btn {
    margin: 16px auto 0;
  }
  .company-improvements-container {
    padding-top: 61px;
    padding-bottom: 35px;
  }
  .company-improvements-grid {
    gap: 30px;
  }
  .company-improvements-item p {
    font-size: 18px;
    margin-top: 10px;
  }
  .company-improvements-title {
    margin: 0 auto 35px;
  }
  .company-improvements-item .company-improvements-icon {
    width: 96px;
    height: 96px;
  }
}

@media screen and (max-width: 767px) {
  .company-container {
    padding: 40px 0 70px;
  }
  .company-values-parts-container {
    margin-bottom: 30px;
    margin-top: 60px;
  }
  .company-trusted-partner-left-column {
    padding-right: 0;
    max-width: 100%;

  }
  .company-trusted-partner-wrap > div.company-trusted-partner-right-column {
    max-width: 100%;
  }
  .company-trusted-partner-container {
    padding: 40px 0;
  }
  .company-trusted-partner-title, .company-trusted-partner-text {
    margin-bottom: 10px;
  }
  .get-started-origin {
    padding: 35px 0rem;
  }
  .company-improvements-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .company-improvements-item .company-improvements-icon {
    width: 86px;
    height: 86px;
  }
  .company-improvements-item p {
    font-size: 17px;
    margin-top: 10px;
  }
  .company-improvements-container {
    padding-top: 41px;
    padding-bottom: 35px;
  }
  .company-subtitle {
    font-size: 15px;
  }

}
