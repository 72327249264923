.get-started-container {
    display: flex;
    padding-top: 3rem;
    flex-direction: column;
    border-bottom: 1px solid rgb(62,67,72);
    background-color: rgb(13, 19, 25);
}

.get-started-text {
    margin-bottom: .5rem;
   
}
.get-started-text h2{
    color: var(--white);
}


.get-started-button {
    background-color: rgb(10, 98, 207);
    color: aliceblue;
    border: none;
    padding: 10px 22px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 2rem;
}