/* Header section w/ form */
.mps-top {
    position: relative;
    padding-top: 130px;
    padding-bottom: 150px;
}
.mps-top::after {
    position: absolute;
    background: url(/src/assests/Home/blue_light_bg.png);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    background-position: bottom;
    width: 100%;
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    padding-top: 14.74%;
    z-index: -1;
}

.mps-content {
    margin: 0 auto;
    padding-top: 2rem;
    align-items: center;
    text-align: center;
}

.mps-content p {
	text-align: center;
	font-size: 16px;
	color: var(--text-color);
	line-height: 120%;
	margin: 30px auto 40px;
	max-width: 767px;
	width: 100%;
}
.mps-content .navigate-use-btn .get-started-btn {
	background-color: var(--purple);
	color: #fff;
	padding: 15px 33px;
}
.mps-content .navigate-use-btn .get-started-btn:hover {
	background-color: #8e8eff;
}
.mps-content h1 {
    color: #096dda;
}

.mps-bottom {
    flex-grow: 1;
}
.mps-bottom .mps-form {
    display: none;
}

.mps-form {
    position: absolute;
    width: 55%;
    transform: translate(40%, -15%);
}

/* Solutions section accordian */

.mps-solutions-container {
	background-color: #EAF1FC;
	padding-top: 120px;
	padding-bottom: 180px;
}


.mps-solutions-top h1 {
	max-width: 634px;
	width: 100%;
}

.mps-solutions-parts-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.mps-solutions-parts-left {
	max-width: calc(60.1% - 87px);
	margin-right: 87px;
	position: relative;
	z-index: 2;
}

.mps-solutions-parts-right {
	max-width: 39.9%;
	width: 100%;
	position: relative;
}
.mps-solutions-parts-right::after {
	content: "";
	background: radial-gradient(50% 50% at 50% 50%, rgba(191, 95, 255, 0.2) 0%, rgba(191, 95, 255, 0) 100%);
	width: 814px;
	height: 814px;
	position: absolute;
	bottom: -250px;
	right: 0px;
	z-index: 0;
}
.mps-solutions-parts-img-wrap {
	position: relative;
	width: 100%;
	padding-top: 128.8%;
}
.mps-solutions-parts-img-wrap img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 30px;
}
/* Shared styles for divs */
.mps-solutions-parts-div {
	padding-top: 20px;
	margin-bottom: 0;
}

.mps-solutions-parts-icon {
    margin-bottom: 0.5rem;
}
.mps-solutions-parts-content > div.MuiAccordion-root.MuiAccordion-rounded {
	box-shadow: unset;
	border-radius: 10px;
	padding: 20px 50px;
}
.mps-solutions-parts-content > div.MuiAccordion-root.MuiAccordion-rounded .MuiAccordionSummary-root {
	padding: 0;
	min-height: 40px;
}
.mps-solutions-parts-content > div.MuiAccordion-root.MuiAccordion-rounded .MuiAccordionSummary-content {
	font-size: 24px;
	font-weight: 800;
	line-height: 120%;
	color: var(--heading-color);
	margin: 0;
}
.mps-solutions-parts-content  .MuiCollapse-root.MuiCollapse-vertical .MuiAccordionDetails-root {
	padding: 0;
	font-size: 16px;
	color: var(--text-color);
	font-weight: 400;
	line-height: 140%;
}
.mps-solutions-parts-content {
    text-align: left;
}

.mps-solutions-parts-content h3 {
    margin-top: 0;
    font-size: x-large;
    font-weight: 300;
}

.mps-solutions-parts-content p {
    width: 90%;
    font-size: medium;
    font-weight: 200;
}

/* Info Section */
.mps-info-container {
  padding-top: 160px;
  padding-bottom: 160px;
}

.mps-info-header {
	margin-bottom: 55px;
}

.mps-info-header  h2{
  line-height: 140%;
}
.mps-info-content {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
  position: relative;
}
.mps-info-content::before {
	content: "";
	background: radial-gradient(50% 50% at 50% 50%, rgba(191, 95, 255, 0.2) 0%, rgba(191, 95, 255, 0) 100%);
	width: 814px;
	height: 814px;
	position: absolute;
	bottom: -330px;
	left: -340px;
	z-index: 0;
}
.mps-info-left-column {
	max-width: calc(55% - 100px);
	margin-right: 100px;
	width: 100%;
}



.mps-info-left-img-wrap {
	position: relative;
	width: 84.5%;
	padding-top: 75.7%;
}
.mps-info-left-column img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0 130px 130px 130px;
	position: absolute;
	top: 0;
	left: 0;
}
.mps-info-right-column {
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	max-width: 41.7%;
	width: 100%;
}

.mps-info-column {
	border-bottom: 1px solid #D1D5DB;
	padding-bottom: 34px;
	margin-bottom: 34px;
}
.mps-info-column h4{
  line-height: 150%;
}
.mps-info-column h4 span {
	margin-right: 10px;
	display: inline-flex;
	vertical-align: bottom;
}
.mps-info-column-middle {
    width: 50%;
    padding: 0 3rem;
}



.mps-info-column p {
	padding: 15px 0 0;
	font-size: 16px;
	line-height: 150%;
	font-weight: 400;
	color: #4B5563;
}

/* Innovation Section */

.mps-innovation-container {
	position: relative;
	z-index: 1;
	padding-bottom: 60px;
}
.mps-innovation-header {
	margin-bottom: 113px;
}
.mps-innovation-header h1 {
	max-width: 696px;
	width: 100%;
	text-align: center;
	margin: 0 auto;
}

.mps-innovation-row {
    display: flex;
    padding-top: 1rem;
}

.mps-innovation-column h2 {
    font-size: xx-large;
    font-weight: 300;
    padding: 1.5rem 0;
    margin: 0;
}

.mps-innovation-column p {
    width: 80%;
    font-size: large;
    margin: 0;
    font-weight: 200;
}
/* modal popup design */
.solution-modal .payment-form-container {
	max-width: 57vw;
  width: 100%;
	margin: 0 auto;
	padding: 20px;
	border: 1px solid rgb(255, 255, 255);
	border-radius: 15px;
	background-color: rgb(255, 255, 255);
	top: 50%;
	transform: translateY(-50%);
	position: fixed;
	left: 0;
	right: 0;
	max-height: 80vh;
	overflow: auto;
}
.solution-modal .form-title {
	font-size: 24px;
	font-weight: 800;
	margin-bottom: 0px;
}
.solution-modal .form-subtitle {
	text-align: center;
	font-size: 16px;
	margin-bottom: 4rem;
}
.solution-modal .payment-form-container > .MuiButtonBase-root.MuiButton-root {
	min-width: unset;
	width: auto;
	position: absolute;
	right: 20px;
}
.solution-modal .MuiButtonBase-root.MuiButton-root .MuiButton-icon.MuiButton-startIcon {
	margin-right: 0;
}
.solution-modal .MuiButtonBase-root.MuiButton-root .MuiButton-icon.MuiButton-startIcon .MuiSvgIcon-root{
 
  width: 1.4em;
  height: 1.4em;
}
.solution-modal .form-grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.solution-modal .form-grid .form-group {
	max-width: calc(50% - 20px);
	width: 100%;
	margin-right: 20px;
}
.solution-modal .form-grid .form-group:nth-child(2n) {
	margin-right: 0;
}
.solution-modal .form-grid div:not(.form-group) {
	max-width: 100%;
	width: 100%;
}
.solution-modal .form-grid button {
	width: fit-content;
}
.solution-modal .nst_gateway_signupform_handle_button {
	display: flex;
	justify-content: center;
  margin-bottom: 20px;
}
.solution-modal  .form-group .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  position: absolute;
  right: 10px;
  top: 50%;
  cursor: pointer;
}
.mps-solutions-parts-content .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
	max-width: 439px;
	width: 100%;
}
@media screen and (max-width:1200px) {
  .mps-top {
    padding-top: 40px;
    padding-bottom: 100px;
  }
  .mps-top::after {
    padding-top: 13.74%;
  }
  .mps-solutions-parts-left {
    max-width: calc(60.1% - 27px);
    margin-right: 27px;
  }
  .mps-content p {
    font-size: 14px;
    margin: 20px auto 20px;
  }
  .mps-solutions-parts-div {
    padding-top: 15px;
  }
  .mps-solutions-container {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .mps-solutions-parts-content > div.MuiAccordion-root.MuiAccordion-rounded {
    padding: 10px 20px ;
  }
  .mps-solutions-parts-content > div.MuiAccordion-root.MuiAccordion-rounded .MuiAccordionSummary-content {
    font-size: 20px;
  }
  .mps-solutions-parts-content .MuiCollapse-root.MuiCollapse-vertical .MuiAccordionDetails-root {
    padding: 0 0 15px 0;
    font-size: 15px;
  }
  .mps-info-container {
    padding-top: 70px;
    padding-bottom: 40px;
  }
  .mps-info-left-column {
    max-width: calc(45% - 10px);
    margin-right: 10px;
  }
  .mps-info-right-column {
    max-width: 54.6%;
    width: 100%;
  }
  .mps-info-column p {
    font-size: 14px;
    line-height: 150%;
  }
  .mps-info-header {
    margin-bottom: 25px;
  }
  .mps-info-column {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .mps-info-left-column img {
    border-radius: 0 60px 60px 60px;
  }
  .mps-innovation-header {
    margin-bottom: 63px;
  }
  .mps-innovation-container {
    padding-bottom: 10px;
  }
  .solution-modal .payment-form-container {
    max-width: 700px;
    margin: 0 auto;
  }
  .solution-modal .form-subtitle {
    margin-bottom: 2rem;
  }
}
@media screen and (max-width:767px) {
  .mps-top {
    padding-top: 10px;
    padding-bottom: 60px;
  }
  .mps-solutions-parts-container {
    flex-direction: column-reverse;
  }
  .mps-solutions-parts-left {
    max-width: 100%;
    margin-right: 0;
  }
  .mps-info-container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mps-solutions-parts-right,.mps-info-right-column  {
    max-width: 100%;
  }
 
  .mps-info-left-column {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .mps-info-left-img-wrap {
    margin: 0 auto;
  }
  .mps-innovation-container {
    padding-bottom: 0;
  }
  .mps-info-content::before {
    width: 654px;
    height: 654px;
    bottom: 240px;
    left: -150px;
  }
  .solution-modal .payment-form-container {
    max-width: 90%;
  }
  .solution-modal .form-title {
    font-size: 18px;
    line-height: 120%;
    padding: 0;
  }
  .solution-modal .payment-form-container > .MuiButtonBase-root.MuiButton-root {
    right: 6px;
    top: 6px;
  }
  .solution-modal .form-subtitle {
    margin-bottom: 1.3rem;
  }
  .solution-modal .form-grid .form-group {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 13px;
  }
  .form-group label {
    font-size: 14px;
  }
  .mps-solutions-parts-content .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
    max-width: 100%;
  }
}