@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

:root {
  --text-color: #414141;
  --para-size: 18px;
  --heading-color: #1F2B39;
  --white: #fff;
  --purple: #5F5FFF;

}

* {
  font-family: "Mulish", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* For Chrome, Safari, and Edge */


*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
 
  background: #EEE
}

*::-webkit-scrollbar-thumb {
  background-color: #c2d1e2; 
 
}
* {
  scrollbar-width: thin;
  scrollbar-color: #105EB2 #EEE
}

#root {
  overflow: hidden;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 40px;
  font-weight: 800;
}

h2 {
  font-size: 40px;
  font-weight: 800;
  color: var(--heading-color);
}

h3 {
  font-size: 32px;
  font-weight: 800;
  color: var(--heading-color);
}

h4 {
  font-size: 24px;
  font-weight: 800;
  color: var(--heading-color);
}

h5 {
  font-size: 18px;
  font-weight: 800;
  color: var(--heading-color);
}

p {
  font-size: 18px;
  color: var(--text-color);
  line-height: 150%;
}
img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}
.nst-gate-page {
	margin-top: 83.223px;
}

@media screen and (max-width:1200px) {
  h1 {
    font-size: 38px;
  }

  h2 {
    font-size: 33px;
  }
  
  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 22px;
  }
  

}
@media screen and (max-width:1024px) {
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }
}
@media screen and (max-width:898px) {
  h1 {
      font-size: 30px;
  }h2 {
    font-size: 24px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 17px;
  }
 
}
@media screen and (max-width:768px) {
  h1 {
    font-size: 25px;
}
h2 {
	font-size: 22px;
}
h3 {
	font-size: 20px;
}
h5 {
	font-size: 15px;
}
p {
	font-size: 14px;
}
}
@media screen and (max-width:475px) {
  h2 {
    font-size: 20px;
  }
}