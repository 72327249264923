/* Header section w/ form */
.top {
	position: relative;
	background-image: url('/src/assests/Home/hero_banner.png');
	background-size: 100%;
	background-position: center;
	color: #fff;
	padding: 3rem 4rem 11rem;
	/* height: 50vh; */
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
}
.content {
	width: 100%;
	margin: 0;
	max-width: 859px;
}

.content p {
    font-size: medium;
    text-align: center;
    color: #fff;
}

.content h1 {
    font-size: 40px;
    font-weight: 500;
    padding: 0;
    text-align: center;
}

.bottom {
    flex-grow: 1;
    height: 70vh;
    position: relative;
}

/* Adjust form positioning */
.form {
    position: absolute;
    transform: translate(27%, -28%);
    margin-bottom: 5rem;
    border-bottom: 2px solid rgb(242, 241, 240);
}

/* Contaact us page */
.contact-us {
    margin: 0 21vw;
    padding-bottom: 6rem;
}

.contact-top {
    font-size: x-large;
    margin-bottom: 1rem;
}

.contact-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.column {
    flex: 1;
}

.column h4 {
    color: black;
    padding-bottom: 0.1rem;
    margin: 0;
}

.column p {
    color: black;
    margin: 0;
    padding: 0.2rem 0;
}

.submit-claim {
    margin-top: 1rem;
    grid-column: span 2;
    width: 58%;
    padding: 10px;
    background-color: rgb(98, 211, 239);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s;
    justify-self: center;
}

.submit-claim:hover {
    background-color: rgb(4, 204, 254);
}

/* Custom div section */

.custom-div {
    background-color: rgb(245, 252, 255);
    padding: 5rem 7rem;
    border-radius: 10px;
}

.custom-div-header p {
    margin: 0;
    padding-bottom: 1rem;
    font-weight: 200;
}

.custom-div-header h2 {
    font-weight: 300;
    font-size: xx-large;
}

.custom-div-content {
    display: flex;
    justify-content: space-between;
}

.custom-div-column {
    flex: 1;
}

.custom-div-bordered {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    width: 60%;
    padding: 1rem 0;
}

.custom-div-bordered button {
    margin-bottom: 1rem;
    padding: 10px 33px;
}

.custom-div-bordered h3 {
    font-weight: 300;
    font-size: 25px;
}

.custom-div-tab {
    margin: 2rem 0;
}

.custom-div-tab h3 {
    font-weight: 300;
    font-size: 25px;
}

.custom-div-button {
    padding: 10px 33px;
    background-color: #ffffff;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
}

.custom-div-button:hover {
    background-color: #007bff;
    color: white;
    border: 1px solid transparent;
}

.nst_gateway_signup_form_wrapper .form-group {
    margin-bottom: 15px;
}

.signup-bottom .bottom {
    height: unset;
}

.nst_gateway_signup_form_wrapper .form-group input[type="text"],
.nst_gateway_signup_form_wrapper .form-group input[type="email"] {
  
  border: 0;
  box-sizing: unset;

}

.nst_gateway_signup_form_wrapper .form-group fieldset {
    border: 1px solid #ccc;
    padding: 16.5px 14px;
}

.signup_sec.comm_auth_sec {
   
    border-bottom: 2px solid rgb(242, 241, 240);
    max-width: 60vw;
    width: 100%;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    position: relative;
    z-index: 9;
    margin-bottom: 20px;
    margin: -150px auto 100px;
}

.nst_gateway_signup_form_wrapper {
    margin-top: 20px;
}

.nst_gateway_signup_form_wrapper input {
  font-size: 14px;
  padding: 1rem;
}
.nst_gateway_signup_form_wrapper input::placeholder{
  font-size: 14px;
}

.nst_gateway_signupform_handle_button {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.nst_gateway_signupform_handle_button button[type="submit"] {
    background-color: #5f5fff;
    border-radius: 30px;
    padding: 10px 31px;
    color: white;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 800;
    position: relative;
    box-shadow: 0px 15px 20px 0px #1e1e6f33;
    font-family: "Mulish", sans-serif;
    margin-bottom: 0;
    line-height: 120%;
    margin-top: 0;
}

.nst_gateway_signupform_handle_button button[type="submit"]:hover {
    background-color: #8e8eff;
}

.nst_gateway_signupform_handle_button button[type="button"] {
    border: 1px solid var(--purple);
    background-color: var(--white);
    border-radius: 30px;
    transition: all 0.5s ease;
    color: var(--purple);
    font-weight: 700;
    display: flex;
    align-items: center;
    padding: 10px 31px;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: capitalize;
}

.nst_gateway_signupform_handle_button button[type="button"]:hover {
    background-color: var(--purple);
    color: var(--white);
}

.signup-content .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.form-group .MuiInputBase-root.MuiOutlinedInput-root {
    justify-content: space-between;
}

@media (max-width: 1920px) {
    .signup-content .content {
        justify-content: flex-start;
    }
   
}
@media (max-width: 1600px) {
    .signup-content .content {
        justify-content: flex-start;
    }
  
    .content h1 {
        font-size: 35px;
    }
   
}
@media (max-width: 1440px) {
    .signup_sec.comm_auth_sec {
        margin: -80px auto 60px;
    }
    .top {
        padding: 3rem 4rem 6rem;
       
    }
    .content h1 {
        font-size: 30px;
    }
}
@media (max-width: 1024px) {
   
    .top {
        padding: 3rem 1rem 6rem;
    }
    .signup_sec.comm_auth_sec {
        max-width: 80vw;
    }
}
@media screen and (max-width: 999px) {
 

    .form {
        position: absolute;
        transform: translate(27%, -8%);
        margin-bottom: 5rem;
        border-bottom: 2px solid rgb(242, 241, 240);
    }

    .bottom {
        height: 85vh;
    }

    .contact-us {
        margin: 0 11vw;
        padding-bottom: 6rem;
    }

    .custom-div-tab h3 {
        font-size: 20px;
    }

    .custom-div-bordered h3 {
        font-size: 20px;
    }
    .signup-content .content {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
   

  


    .form {
        position: absolute;
        transform: translate(2%, -15%);
        margin-bottom: 5rem;
        border-bottom: 2px solid rgb(242, 241, 240);
    }

    .bottom {
        height: 85vh;
    }

    .contact-us {
        margin: 0 5vw;
        padding-bottom: 6rem;
    }

    .custom-div {
        padding: 1rem;
    }

    .custom-div-tab h3 {
        font-size: 20px;
        width: 100%;
    }

    .custom-div-bordered h3 {
        font-size: 20px;
        width: 100%;
    }

 

    .nst_gateway_signup_form_wrapper .form-group fieldset {
        top: -3px;
        padding: 6.5px 4px;
        height: 52px;
    }

    .nst_gateway_signupform_handle_button button[type="submit"] {
        margin-top: 0;
        font-size: 16px;
        min-width: 103px;
    }

    .nst_gateway_signupform_handle_button button[type="button"] {
        margin: 0;
        font-size: 16px;
        padding: 7px 21px;
    }
    .signup_sec.comm_auth_sec {
        max-width: 90vw;
    }
}

@media screen and (max-width: 600px) {
    .form {
        position: static;
        transform: translate(0%, 0%);
        margin-bottom: 2rem;
        border-bottom: 2px solid rgb(242, 241, 240);
    }

    .bottom {
        flex-grow: 1;
        height: auto;
    }

    .contact-top {
        text-align: center;
    }

    .column {
        text-align: center;
    }
}

@media screen and (max-width: 480px) {
   

    .content h1 {
        font-size: 22px;
    }

    .contact-details {
        flex-direction: column;
        justify-content: space-evenly;
    }

    .column {
        padding-bottom: 1rem;
    }

    .custom-div-bordered {
        width: 99%;
        border: none;
    }

    .content {
        width: 100%;
    }

  

    .nst_gateway_signupform_handle_button button[type="submit"] {
        margin-top: 0;
        font-size: 14px;
        padding: 12px 25px;
    }

    .nst_gateway_signupform_handle_button button[type="button"] {
        font-size: 14px;
    }

    .nst_gateway_signup_form_wrapper .form-group fieldset {
        top: 0px;
        padding: 6.5px 4px;
        height: 45px;
    }

    .nst_gateway_signup_form_wrapper p.MuiFormHelperText-root.Mui-error {
        margin-right: 0;
        font-size: 0.8em;
        margin-top: 0;
    }
}
